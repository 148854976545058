import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AppConfig } from '../../../apps/whitelabel/src/core/WhiteLabelConfig.class';

declare const cfg: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private title: Title
  ) {}

  htmls: any = {};
  texts: any = {};
  cms_banners: any = {};
  links: any = {};

  appID: string;
  appName: string;

  async getConfig() {
    if (cfg) {
      return cfg;
    }
    return new Promise(res => {
      this.document.addEventListener('onConfigLoaded', event => {
        res(cfg);
      });
    });
  }

  async getAppConfig(): Promise<AppConfig> {
    let config = await this.getConfig();
    return config && config.app;
  }

  async bootstrap() {
    const config = await this.getConfig();
    this.setupApp(config.app);
    this.loadTheme(config.theme);
    this.loadHtmls(config.htmls);
    this.loadTexts(config.texts);
    this.loadCmsBanners(config.cms_banners);
    this.loadLinks(config.links);
  }

  setupApp(appConfig) {
    if (!appConfig) {
      throw new Error('Không tìm thấy cài đặt ứng dụng');
    }
    this.title.setTitle(appConfig.appName);
    this.appID = appConfig.appId;
    this.appName = appConfig.appName;
  }

  loadTheme(theme) {
    if (!theme) {
      throw new Error('Không tìm thấy cài đặt giao diện');
    }
    const root = this.document.documentElement;
    for (let variable in theme) {
      if (!theme.hasOwnProperty(variable)) {
        continue;
      }
      root.style.setProperty(`--${variable}`, theme[variable]);
    }
  }

  loadHtmls(htmls) {
    this.htmls = htmls;
  }

  loadTexts(texts) {
    this.texts = texts;
  }

  loadCmsBanners(cms_banners) {
    this.cms_banners = cms_banners;
  }

  loadLinks(links) {
    this.links = links;
  }
}
