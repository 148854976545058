<div class="modal-header py-2">
  <h5 style="margin: auto 0 !important;" class="text-center">Quên mật khẩu</h5>
  <button type="button" class="btn text-large" data-dismiss="modal">&times;</button>
</div>
<div class="modal-body sweet">
  <div class="form-group m-0">
    <form class="mt-3"  >
      <label>
        <p class="mb-2">
          Vui lòng cung cấp <b>số điện thoại</b> mà bạn đã dùng khi đăng ký tài khoản eTelecom.
          Chúng tôi sẽ gửi bạn mã xác nhận cho phép bạn đặt lại mật khẩu.
        </p>
      </label>
      <label class="w-100">
        <input type="text" required class="form-control" (keypress)="numberOnly($event)" placeholder="Số điện thoại" name="phone" [(ngModel)]="phone"/>
      </label>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-outline" data-dismiss="modal">Hủy</button>
  <button class="btn btn-custom btn-primary" (click)="doForgotPassword()" [disabled]="loading">
    <i *ngIf="loading" class="fa fa-spin fa-circle-o-notch"></i>
    Đặt lại mật khẩu
  </button>
</div>
