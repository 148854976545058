import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {CommonUsecase} from 'apps/shared/src/usecases/common.usecase.service';
import { UserService } from '../../../../../../core/src/services/user.service';
import {AuthenticateService, AuthenticateStore, ConfigService} from '@etop/core';
import * as validatecontraints from '../../../../../../core/src/services/validation-contraints.service';
import { ShopAccountAPI } from '@etop/api';
import { ActivatedRoute } from '@angular/router';
import { ForgotPasswordComponent } from '../../../components/forgot-password/forgot-password.component';
import { ModalController } from 'apps/core/src/components/modal-controller/modal-controller.service';
import { UserSource } from '@etop/models';



enum View {
  NONE = 'none',
  PHONE_SUBMIT = 'phonesubmit',
  PHONE_VERIFY = 'phoneverify',
  REGISTER = 'register',
  LOGIN = 'login',
  CREATE_SHOP = 'createshop'
}

@Component({
  selector: 'etelecom-cs-mobile-register',
  templateUrl: './mobile-register.component.html',
  styleUrls: ['./mobile-register.component.scss']
})
export class MobileRegisterComponent implements OnInit {
  @ViewChild('passwordInput', {static: false}) passwordInput: ElementRef;
  invitation_token = '';
  e_aff = '';
  phone: string;
  login: string;
  password: string;
  verify_code: string;
  email: string;
  fullname: string;
  shop_name: string;
  userName;

  view = View;
  _currentView: View = View.NONE;
  error = false;
  errorMessage = '';

  loading = false;

  countdown = 60;

  signupData: any = {
    agree_email_info: true,
    agree_tos: false,
    ref_aff:'',
  };
  session: any;
  onFocusEmail = false;
  loadingView = true;
  invitation: string;
  source: UserSource = 'telecom';

  constructor(
    private commonUsecase: CommonUsecase,
    private userService: UserService,
    private config: ConfigService,
    private auth: AuthenticateStore,
    private activatedRoute: ActivatedRoute,
    private modalCtrl: ModalController
  ) {
  }

  async ngOnInit() {
    this._currentView = View.PHONE_SUBMIT;
    this.invitation = this.activatedRoute.snapshot.queryParamMap.get('invitation');
    if (this.invitation) {
      this.signupData.phone = this.invitation
    }
    this.commonUsecase.redirectIfAuthenticated().then(() => (this.loadingView = false));
  }

  async onEnterLoginInput() {
    if (!this.password) {
      if (this.passwordInput) {
        setTimeout(_ => {
          this.passwordInput.nativeElement.focus();
        });
      }
    } else {
      this.onLogin().then();
    }
  }

  showPassword() {
    if (this.passwordInput) {
      setTimeout(_ => {
        this.passwordInput.nativeElement.type = 'text';
      });
    }
  }

  hidePassword() {
    if (this.passwordInput) {
      setTimeout(_ => {
        this.passwordInput.nativeElement.type = 'password';
      });
    }
  }

  async onLogin() {
    if (!this.phone || !this.password) {
      toastr.error('Vui lòng nhập đầy đủ thông tin đăng nhập để tiếp tục.');
      return;
    }

    this.loading = true;
    this.phone = this.phone.replace(/\s/g, "");

    try {
      let res = await this.commonUsecase.login({
        login: this.phone,
        password: this.password
      });
      if ( res == "create_shop" ) {
        this.userName = this.auth.snapshot.user.full_name;
        this._currentView = View.CREATE_SHOP;
      }
    } catch (e) {
      toastr.error(e.message, 'Đăng nhập thất bại!');
    }
    this.loading = false;
  }

  currentView(view: View) {
    return this._currentView === view;
  }

  toView(view: View) {
    this._currentView = view;
    if (view === View.PHONE_SUBMIT) {
      this.loading = false;
    }
  }

  validatePhoneNumber(phone) {
    if (phone && phone.match(/^0[0-9]{9,10}$/)) {
      if (phone.length < 10) {
        toastr.error('Vui lòng nhập số điện thoại di động 10 số');
      }
      return true;
    }
    toastr.error('Vui lòng nhập số điện thoại hợp lệ');
  }

  async reSendverifyphone() {
    await this.userService.sendPhoneVerification(this.phone);
    this.countTimeVerify();
  }

  async signUp() {
    this.loading = true;
    this.signupData.phone = this.phone;
    this.signupData.shop_name = this.shop_name;
    const signupData = this.signupData;
    let phone = signupData.phone;
    let email = signupData.email;
    
    try {
      if (!signupData.full_name) {
        this.loading = false;
        return toastr.error('Vui lòng nhập Tên đầy đủ.');
      }

      if(!this.shop_name && !this.invitation) {
        this.loading = false;
        throw new Error('Vui lòng nhập Tên công ty đầy đủ.');
      }

      phone = (phone && phone.split(/-[0-9a-zA-Z]+-test/)[0]) || '';
      phone = (phone && phone.split('-test')[0]) || '';

      if (!phone || !phone.match(/^[0-9]{9,10}$/)) {
        return toastr.error('Số điện thoại không đúng');
      }

      email = (email && email.split(/-[0-9a-zA-Z]+-test/)[0]) || '';
      email = email.split('-test')[0];

      if (!validatecontraints.EmailValidates[0].check(email)) {
        this.loading = false;
        return toastr.error('Địa chỉ email không hợp lệ.');
      }

      if (!signupData.password) {
        this.loading = false;
        return toastr.error('Mật khẩu không được để trống.');
      }

      if (signupData.password !== signupData.confirm) {
        this.loading = false;
        return toastr.error('Mật khẩu nhập lại không chính xác.');
      }

      if (!signupData.agree_tos) {
        this.loading = false;
        return toastr.error('Vui lòng đồng ý với điều khoản sử dụng dịch vụ.');
      }
      await this.commonUsecase.register(signupData, this.source);
      this.loading = false;
    } catch (e) {
      this.loading = false;
      return toastr.error('Đăng ký thất bại! ' + e.message);
    }
  }

  async createShop(){
    const signupData = this.signupData;
    if(!signupData.shop_name) {
      toastr.error('Vui lòng nhập Tên công ty đầy đủ.');
      return;
    }
    this.loading = true;
    try{
      const registerShopRequest: ShopAccountAPI.RegisterShopRequest = {
        name: signupData.shop_name,
        phone: this.auth.snapshot.user.phone
      }
      await this.userService.registerShop(registerShopRequest);
      await this.commonUsecase.login({
        login: this.phone,
        password: this.password
      });
      this.loading = false;
    } catch (e) {
      toastr.error(e.message, 'Tạo công ty thất bại thất bại!');
    }

  }

  async checkUserRegistration(phone) {
    try {
      if (!phone) {
        throw new Error('Vui lòng nhập số điện thoại để tiếp tục!');
      }
      const recaptcha_token = await AuthenticateService.getReCaptcha(this.config.get('recaptcha_key'));
      const data = {
        phone,
        recaptcha_token
      };
      const check = await this.userService.checkUserRegistration(data);
      if (check.exists) {
        this.password = null;
        this._currentView = View.LOGIN;
        return;
      }
      this.session = await this.userService.initSession();
      this.auth.updateToken(this.session.access_token);
      await this.userService.sendPhoneVerification(phone);
      this._currentView = View.PHONE_VERIFY;
    } catch (e) {
      debug.error('ERROR in Login checkUserRegistration', e);
      throw e;
    }
  }

  async forgotPassword() {
    const modal = this.modalCtrl.create({
      component: ForgotPasswordComponent,
      componentProps: {
        phone: this.phone
      },
    });
    modal.show().then();
    modal.onDismiss().then((data) => {
      if (data.data) {
        debug.log('openResetPassword register', data);
        this.phone = data.data.phone;
      }
    });
  }

  async onPhoneSubmit() {
    this.loading = true;
    this.signupData.phone = this.signupData.phone.replace(/\s/g, "");
    this.phone = this.signupData.phone;
    try {
      if (!this.phone) {
        this.loading = false;
        return toastr.error('Vui lòng nhập số điện thoại!');
      }
      let isTest = this.phone.split(/-[0-9a-zA-Z]+-test$/).length > 1;
      if (isTest || this.validatePhoneNumber(this.phone)) {
        await this.checkUserRegistration(this.phone);

        if (this._currentView === View.PHONE_VERIFY) {
          this.verify_code = '';
          this.countTimeVerify();
        }
      }
    } catch (e) {
      debug.error('ERROR in Login onPhoneSubmit', e);
      toastr.error(e.message);
    }
    this.loading = false;
  }

  countTimeVerify() {
    this.countdown = 60;
    const interval = setInterval(() => {
      if (this.countdown < 1) {
        clearInterval(interval);
        debug.log('countdown', this.countdown);
      } else {
        this.countdown -= 1;
      }
    }, 1000);
  }

  async onPhoneVerify() {
    try {
      if (!this.verify_code) {
        return toastr.error('Vui lòng nhập mã xác nhận!');
      }
      const res = await this.userService.verifyPhoneUsingToken(this.verify_code);
      if (res.code === 'fail') {
        toastr.error(res.msg);
      } else {
        this._currentView = View.REGISTER;
      }
    } catch (e) {
      toastr.error(e.message);
    }
  }
}
