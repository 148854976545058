import { BrowserModule } from '@angular/platform-browser';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { CoreModule } from 'apps/core/src/core.module';
import { SharedModule } from 'apps/shared/src/shared.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonUsecase } from 'apps/shared/src/usecases/common.usecase.service';
import { UtilService } from 'apps/core/src/services/util.service';
import { MaterialModule } from '@etop/shared/components/etop-material/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginModule } from 'apps/shared/src/pages/login/login.module';
import { AppGuard } from 'apps/etelecom-cs/src/app/app.guard';
import { IsUserActiveGuard } from './is-user-active.guard';
import { CONFIG_TOKEN } from '@etop/core/services/config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { EtopPipesModule } from 'libs/shared/pipes/etop-pipes.module';
import { EtopFilterModule } from '@etop/shared/components/etop-filter/etop-filter.module';
import { EtopFormsModule, EtopMaterialModule } from '@etop/shared';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import { ETelecomCommonUsecase } from './usecases/e-telecom-common.usecase.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DesktopRegisterComponent } from './pages/register/desktop-register/desktop-register.component';
import { RegisterComponent } from './pages/register/register.component';
import { MobileRegisterComponent } from './pages/register/mobile-register/mobile-register.component';
import { CreateExtensionWithSubcriptionComponent } from './components/create-extension-with-subcription/create-extension-with-subcription.component';
import { PaycardTurtorialModalComponent } from './components/paycard-turtorial-modal/paycard-turtorial-modal.component';
import { ExportCallLogModalComponent } from './components/export-call-log-modal/export-call-log-modal.component';

registerLocaleData(localeVi);

const services = [UtilService];

const routes: Routes = [
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [IsUserActiveGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'admin-login',
    loadChildren: () =>
      import(
        'apps/etelecom-cs/src/app/pages/admin-login/admin-login.module'
      ).then((m) => m.AdminLoginModule),
  },
  {
    path: 'auth-zalo-oa',
    loadChildren: () =>
      import(
        'apps/etelecom-cs/src/app/pages/auth-zalo-oa/auth-zalo-oa.module'
      ).then((m) => m.AuthZaloOaModule),
  },
  {
    path: 's',
    loadChildren: () =>
      import('apps/etelecom-cs/src/app/etelecom-cs/etelecom-cs.module').then(
        (m) => m.EtelecomCsModule
      ),
  },
  {
    path: 'delete-shop',
    loadChildren: () =>
      import(
        'apps/etelecom-cs/src/app/pages/delete-shop/delete-shop.module'
      ).then((m) => m.DeleteShopModule),
  },
  {
    path: '**',
    redirectTo: 's/-1/settings',
  },
];

@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DesktopRegisterComponent,
    RegisterComponent,
    MobileRegisterComponent,
    CreateExtensionWithSubcriptionComponent,
    PaycardTurtorialModalComponent,
    ExportCallLogModalComponent,
  ],
  imports: [
    CoreModule.forRoot(),
    SharedModule,
    FormsModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    NgbModule,
    LoginModule,
    EtopFilterModule,
    EtopPipesModule,
    EtopMaterialModule,
    EtopFormsModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [
    { provide: CommonUsecase, useClass: ETelecomCommonUsecase },
    {
      provide: CONFIG_TOKEN,
      useValue: '/assets/configs/appConfigs.json',
    },
    { provide: LOCALE_ID, useValue: 'vi' },
    ...services,
    AppGuard,
    IsUserActiveGuard,
    DecimalPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
