import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../core/src/services/user.service';
import { ModalAction } from '../../../../../core/src/components/modal-controller/modal-action.service';
import {AuthenticateService, AuthenticateStore, ConfigService} from '@etop/core';
import { ModalController } from '../../../../../core/src/components/modal-controller/modal-controller.service';
import { VerifyPhoneModalComponent } from '../../../../../shared/src/pages/login/modals/verify-phone-modal/verify-phone-modal.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'etelecom-cs-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  phone;
  loading = false;
  constructor(
    private userService: UserService,
    private modalDismiss: ModalAction,
    private config: ConfigService,
    private modalController:ModalController,
    private auth: AuthenticateStore
  ) { }

  ngOnInit(): void {
  }
  async doForgotPassword() {
    if(!this.phone) {
      return toastr.error('Vui lòng nhập số điện thoại!');
    }
    this.loading = true;
    try {
      let captcha = await AuthenticateService.getReCaptcha(this.config.get('recaptcha_key'));
      let phone = this.phone;

      const data = {
        phone,
        recaptcha_token: captcha
      };

      const res = await this.userService.requestResetPassword(data);
      this.auth.updateToken(res.access_token);

      let modal = this.modalController.create({
        component: VerifyPhoneModalComponent,
        componentProps: {
          phone: data.phone
        }
      });
      modal.show().then();
      modal.onDismiss().then(() => {
      });

      toastr.success('Một mã xác thực đã được gửi tới số điện thoại của bạn.');

      this.modalDismiss.dismiss(this);
    } catch (e) {
      toastr.error(e.message, 'Yêu cầu mật khẩu mới thất bại!');
    }
    this.loading = false;
  }

  numberOnly(event) {
    const num = Number(event.key);
    if (Number.isNaN(num)) {
      event.preventDefault();
      return false;
    }
  }

  async openResetPassword() {
    const modal = await this.modalController.create({
      component: ResetPasswordComponent,
      componentProps: {
        phone: this.phone
      },
    });
    modal.onDismiss().then(data => {
      if (data.data) {
        debug.log('openResetPassword', data);
        this.modalDismiss.dismiss({ phone: this.phone });
      }
    });
    return await modal.show();
  }
}
